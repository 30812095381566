<template>
  <div>
    <center><img :src="'/images/logo2.png'" class="img1" /></center>
    <h1 class="text">404, Página no encontrada</h1>
  </div>
</template>

<style land="scss" scoped>
@import "../styles/notfound.scss";
</style>
